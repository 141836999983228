var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkoutTAndC"},[_c('div',{staticClass:"heading-size-4"},[_vm._v(_vm._s(_vm.$t('termsAndConditions')))]),_c('p'),_c('p',[_vm._v(" "+_vm._s(_vm.$t('tncNotice'))+" ")]),(_vm.$store.state.lang === 'ja')?_c('v-checkbox',{attrs:{"input-value":_vm.checked,"label":"I agree to the terms and conditions"},on:{"change":_vm.onCheck}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('router-link',{attrs:{"to":{name: 'terms-and-conditions', params: {lang: _vm.$store.state.lang}},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{staticClass:"u-color-primary font-weight-bold",attrs:{"href":href,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('termsAndConditions')))])]}}],null,false,246806315)}),_vm._v(" "+_vm._s(_vm.$t('agreeTC'))+" ")],1)]):_c('v-checkbox',{attrs:{"input-value":_vm.checked,"label":"I agree to the terms and conditions"},on:{"change":_vm.onCheck}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('agreeTC'))+" "),_c('router-link',{attrs:{"to":{name: 'terms-and-conditions', params: {lang: _vm.$store.state.lang}},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{staticClass:"u-color-primary font-weight-bold",attrs:{"href":href,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('termsAndConditions')))])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }