<template>
  <div class="checkoutTAndC">
    <div class="heading-size-4">{{ $t('termsAndConditions') }}</div>
    <p />
    <p>
      {{ $t('tncNotice') }}
    </p>

    <v-checkbox
      v-if="$store.state.lang === 'ja'"
      :input-value="checked"
      @change="onCheck"
      label="I agree to the terms and conditions"
    >
      <div slot="label">
        <router-link
          :to="{name: 'terms-and-conditions', params: {lang: $store.state.lang}}"
          v-slot="{ href, route, navigate }"
          custom
        >
          <a
            class="u-color-primary font-weight-bold"
            :href="href"
            @click.stop="navigate"
            target="_blank"
          >
            {{ $t('termsAndConditions') }}</a
          >
        </router-link>
        {{ $t('agreeTC') }}
      </div>
    </v-checkbox>
    <v-checkbox
      v-else
      :input-value="checked"
      @change="onCheck"
      label="I agree to the terms and conditions"
    >
      <div slot="label">
        {{ $t('agreeTC') }}
        <router-link
          :to="{name: 'terms-and-conditions', params: {lang: $store.state.lang}}"
          v-slot="{ href, route, navigate }"
          custom
        >
          <a
            class="u-color-primary font-weight-bold"
            :href="href"
            @click.stop="navigate"
            target="_blank"
          >
            {{ $t('termsAndConditions') }}</a
          >
        </router-link>
      </div>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTAndC',
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    onCheck(value) {
      this.$emit('check', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkoutTAndC {
  border: 1px solid var(--color-primary);
  padding: 24px;
  background-color: var(--color-white);
  margin-bottom: 16px;
}
</style>
